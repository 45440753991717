<template>
  <div class="login">
    <div class="login-image">
      <div class="logo">
        <img src="@/assets/images/logo1.png" alt="">
      </div>
      <div class="welcome">
        <img src="@/assets/images/welcome.svg">
      </div>
    </div>
    <div class="login-form">
      <div class="login-box">
        <div class="login-logo">
          <b>欢迎登录</b>
        </div>
        <div class="login-box-body">
          <el-form :model="loginForm" :rules="rules" ref="loginForm">
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" placeholder="请输入用户名" clearable size="large"
                        @keyup.enter.native="submitForm('loginForm')">
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" clearable size="large"
                        @keyup.enter.native="submitForm('loginForm')">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-button" size="large"
                         @click="submitForm('loginForm')"
                         :loading="editLoading">登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      },
      editLoading: false
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
        this.editLoading = true
        this.$store.dispatch('login', this.loginForm).then(() => {
          this.editLoading = false
          this.$router.push('/dashboard')
        }).catch(() => {
          this.editLoading = false
        })
      })
    }
  },
  beforeCreate: function () {
    // window.$('body').addClass('login-page')
  },
  beforeDestroy: function () {
    // window.$('body').removeClass('login-page')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.login {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #FFFFFF;

  .login-image {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    height: 100%;
    min-height: 780px;
    background-color: #FFDFAC;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    position: absolute;
    top: 35px;
    left: 42px;

    img {
      height: 42px;
    }
  }

  .welcome {
    width: 600px;
    height: 500px;

    img {
      width: 600px;
      height: 500px;
    }
  }

  .login-form {
    -webkit-box-flex: 1.5;
    -webkit-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    display: flex;
    justify-content: center;

    .login-box {
      width: 400px;

      .login-logo {
        font-size: 35px;
        text-align: center;
        margin-bottom: 25px;
        font-weight: 300;
      }

      .login-box-body {
        background: #fff;
        padding: 20px;
        border-top: 0;
        color: #666;
      }

      .login-button {
        width: 100%;
      }
    }
  }
}

</style>
